@import '@/styles/mixins.scss';

.section {
    width: 100%;
    position: relative;
    overflow: hidden;

    &.bordered {
        border-color: var(--colors-primary-light-80);
        border-width: 1px 0;
        border-style: solid;
    }
}

.blue-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -2;
    background-color: var(--colors-primary-light-95);
}

.wrap {
    max-width: 1440px;
    margin: 0 auto;
    padding: 100px 70px;
}

@media screen and (max-width: 1280px) {
    .wrap {
        padding: 100px 60px;
    }
}

@media screen and (max-width: 960px) {
    .wrap {
        padding: 80px 60px;
    }
}

@media screen and (max-width: 764px) {
    .wrap {
        padding: 80px 40px;
    }
}

@media screen and (max-width: 524px) {
    .wrap {
        padding: 80px 24px;
    }
}
