@mixin flex-column {
    display: flex;
    flex-direction: column;
}

@mixin flex-row {
    display: flex;
    flex-direction: row;
}

@mixin flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

// Hero Headline
@mixin hero-headline {
    font-family: var(--font-inter);
    font-size: var(--hero-headline-size);
    font-style: normal;
    font-weight: 700;
    line-height: 130%;
    letter-spacing: var(--hero-headline-letter-spacing);
    text-align: left;
}

// Headline 1
@mixin headline-1 {
    font-family: var(--font-inter);
    font-size: var(--headline-1-size);
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: 0px;
    text-align: left;
}

// Headline 2
@mixin headline-2 {
    font-family: var(--font-inter);
    font-size: var(--headline-2-size);
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: 0px;
    text-align: left;
}

// Headline 3
@mixin headline-3 {
    font-family: var(--font-inter);
    font-size: var(--headline-3-size);
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: 0px;
    text-align: left;
}

// Headline 4
@mixin headline-4 {
    font-family: var(--font-inter);
    font-size: var(--headline-4-size);
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    letter-spacing: 0px;
    text-align: left;
}

// Headline 5
@mixin headline-5 {
    font-family: var(--font-inter);
    font-size: var(--headline-5-size);
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0px;
    text-align: left;
}

// Headline 6
@mixin headline-6 {
    font-family: var(--font-inter);
    font-size: var(--headline-6-size);
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    letter-spacing: 0px;
    text-align: left;
}

// subtitle L
@mixin subtitle-L {
    font-family: var(--font-inter);
    font-size: var(--subtitle-L-size);
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0px;
    text-align: left;
}

// Subtitle-M
@mixin subtitle-M {
    font-family: var(--font-inter);
    font-size: var(--subtitle-M-size);
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0px;
    text-align: left;
}

// Subtitle-S
@mixin subtitle-S {
    font-family: var(--font-inter);
    font-size: var(--subtitle-S-size);
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0px;
    text-align: left;
}

// Subtitle XS
@mixin subtitle-XS {
    font-family: var(--font-inter);
    font-size: var(--subtitle-XS-size);
    font-style: normal;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 0px;
    text-align: left;
}

// Text L
@mixin text-L {
    font-family: var(--font-inter);
    font-size: var(--text-L-size);
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0px;
    text-align: left;
}

// Text-M
@mixin text-M {
    font-family: var(--font-inter);
    font-size: var(--text-M-size);
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0px;
    text-align: left;
}

// Text-S
@mixin text-S {
    font-family: var(--font-inter);
    font-size: var(--text-S-size);
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0px;
    text-align: left;
}

// Text-XS
@mixin text-XS {
    font-family: var(--font-inter);
    font-size: var(--text-XS-size);
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 0px;
    text-align: left;
}

// CTA Primary
@mixin cta-primary {
    font-family: var(--font-inter);
    font-size: var(--cta-primary-size);
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
}

// CTA Secondary
@mixin cta-secondary {
    font-family: var(--font-inter);
    font-size: var(--cta-secondary-size);
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
}

// Caption-L
@mixin caption-L {
    font-family: var(--font-inter);
    font-size: var(--caption-L-size);
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: 0px;
    text-align: left;
}

// Caption-M-UPPER
@mixin caption-M-upper {
    font-family: var(--font-inter);
    font-size: var(--caption-M-size);
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: 0px;
    text-align: left;
    text-transform: uppercase;
}

// Caption M
@mixin caption-M {
    font-family: var(--font-inter);
    font-size: var(--caption-M-size);
    font-style: normal;
    font-weight: 600;
    line-height: 130%;
    letter-spacing: 0px;
    text-align: left;
}

// Caption-S
@mixin caption-S {
    font-family: var(--font-inter);
    font-size: var(--caption-S-size);
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 14.3px */
    letter-spacing: 0px;
    text-align: left;
}
