@import '@/styles/mixins.scss';

.socials {
    @include flex-row;
    gap: 16px;

    a {
        cursor: pointer;
    }
}

.link-icon {
    @include flex-center;
    height: 24px;
    width: 24px;

    svg {
        stroke: var(--colors-gray);
    }
}
