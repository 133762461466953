@import '@/styles/mixins.scss';

.section-wrap {
    padding-top: 50px !important;
}

.wrapper {
    @include flex-row;
    gap: 60px;
    color: #040f1e;

    &.first-element {
        color: white;
    }
}

.title {
    @include hero-headline;
    margin: 0 0 16px;
}

.description {
    @include text-L;
    margin: 0;
}

.buttons {
    @include flex-row;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 35px;
}

.text-only {
    padding: 11px 10px !important;
}

.icon-text-box-wrapper {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    margin-top: 32px;
    align-items: center;
}

.content {
    flex-grow: 1;
}

.picture-wrapper {
    @include flex-center;
    position: relative;
    transform: translateX(100px);
    opacity: 0.01;

    width: 630px;
    flex-shrink: 0;

    &.enter {
        opacity: 1;
        transform: translateX(0);
        transition: 150ms ease-out;
        transition-delay: 50ms;
    }
}

.top-content {
    transform: translateX(-100px);
    opacity: 0.01;
    margin: 52px 0px;

    transition: 150ms ease-out;

    &.enter {
        opacity: 1;
        transform: translateX(0);
        transition: 150ms ease-out;
        transition-delay: 50ms;
    }
}

.bottom-content {
    opacity: 0.01;

    &.enter {
        opacity: 1;
        transition: 150ms ease-out;
        transition-delay: 100ms;
    }
}

.picture {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
    border-radius: 16px;
    max-height: 500px;

    &.image-shadow {
        border-radius: 8px;
        box-shadow: 0px 0px 25px 0px #0c3a7a1a;
    }
}

@media screen and (max-width: 1280px) {
    .top-content {
        margin: 20px 0 40px;
    }

    .content {
        width: 420px;
        flex-shrink: 0;
    }

    .picture-wrapper {
        overflow: hidden;
    }
}

@media screen and (max-width: 840px) {
    .top-content {
        margin: 20px 0 60px;
    }

    .content {
        width: 100%;
    }

    .picture-wrapper {
        display: none;
    }
}

@media screen and (max-width: 764px) {
    .section-wrap {
        padding: 40px 40px 70px !important;
    }

    .top-content {
        margin: 0 0 40px;
    }

    .title {
        margin: 0 0 12px;
    }
}

@media screen and (max-width: 524px) {
    .section-wrap {
        padding: 40px 24px 60px !important;
    }

    .top-content {
        margin: 0 0 32px;
    }
}
